<template>
    <div>
    <v-row justify="center" v-if="loading">
      <v-col >
        <v-skeleton-loader :loading="loading" type="list-item-two-line"></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-else>
         <div class="d-flex justify-center" v-if="!constancias.length">
            <v-card class="ma-5 pa-5 text-center" width="60%">
                <v-icon size="150px" color="warning">mdi-alert-circle</v-icon>
                <p style="font-family:sans-serif;">No cuentas con ninguna constancia.<br>
                </p>
            </v-card>
         </div>

        <v-container v-else>
            <h1 class="headline ml-5 mt-5">Constancias</h1>
            <span class="ml-5 grey--text text--darken-1 subtitle-2">Haz click en cualquiera de las constancias
                para acceder a su contenido.
            </span>
            <v-row no-gutters class="mt-5">
                <v-col>
                    <v-list nav>
                            <v-list-item class="mb-5" v-for="constancia in constancias" :key="constancia.hash" @click="goToPage(constancia.hash)">
                                <v-list-item-content class="pa-5 elevation-3 rounded">
                                    <v-row no-gutters>
                                        <v-col class="flex-column d-flex" cols="10">
                                            <span class="subtitle-1">CONSEJO: {{ fecha(constancia.fecha) }} </span>
                                            <span class="subtitle-2">{{ userName }}</span>
                                        </v-col>
                                        <v-col class="d-flex justify-end align-center mt-1" cols="2">
                                            <v-icon large color="primary">mdi-certificate</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import { obtenerTodasConstancias } from './constancias/constancias.service';
export default {
    name: 'constanciasConsejero',

    computed: {
        ...mapGetters(["userId", "userName","httpURL_sesiones"]),
        
    },

    async mounted() {
        if (!this.userId) this.$router.push("/sesiones")
        else{
        try {
            this.loading=true;
            const serverResponse = await obtenerTodasConstancias(this.userId);
            this.loading=false;
            if (!serverResponse.ok)  throw new Error(serverResponse.mensaje);
            else this.constancias = serverResponse.constancias;
        }
        catch (error) {
            this.loading=false;
            this.$notificarError(error)
        }
    }
    },
    data() {
        return {
            selectedItem: -1,
            constancias: [],
            loading:false
        };
    },


    methods: {
        goToPage(hash){
            window.open(`${this.httpURL_sesiones}/cgu-constancias/${hash}`,"_blank")
        },
        fecha(date){
            let fecha = DateTime.fromISO(date);
            return fecha.toLocaleString(DateTime.DATE_MED);
        }
    },
};
</script>

<style lang="scss" scoped></style>